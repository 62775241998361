import { Stack, Typography } from "@mui/material";
import {
  MonthlyFinancialsBlockAttributes,
  MonthlyFinancialsBlockContent,
} from "../../../../../../api/types/inputFormTypes";
import SectionAccordion from "../SectionAccordion";
import MonthlyFinancialsInputTable from "./MonthlyFinancialsInputTable";

interface Props {
  attributes: MonthlyFinancialsBlockAttributes;
  content: MonthlyFinancialsBlockContent;
}

const MonthlyFinancialsFormBlock = ({ content }: Props) => {
  return (
    <Stack flex={1} spacing={2.5}>
      {content.tables.map((tableBlock, index) => (
        <SectionAccordion key={index} titleComponent={<Typography variant="h6">{tableBlock.name}</Typography>}>
          <MonthlyFinancialsInputTable columnDefinitions={tableBlock.columns} rowDefinitions={tableBlock.rows} />
        </SectionAccordion>
      ))}
    </Stack>
  );
};

export default MonthlyFinancialsFormBlock;
