import { grey } from "@mui/material/colors";
import { useMemo, useState } from "react";
import DataGrid from "../../../../../../../shared/components/grid/DataGrid";
import { TableBlockColumn, TableBlockRow } from "../../../../../../api/types/inputFormTypes";
import { calculateTotals, getColumns, getRows, RowModel } from "./monthlyFinancialsInputGridDataProvider";

interface Props {
  columnDefinitions: TableBlockColumn[];
  rowDefinitions: TableBlockRow[];
}

const MonthlyFinancialsInputTable = ({ columnDefinitions, rowDefinitions }: Props) => {
  const [rows, setRows] = useState<RowModel[]>(getRows(rowDefinitions));

  const columns = useMemo(() => getColumns(columnDefinitions), [columnDefinitions]);

  const handleRowUpdate = (newRow: RowModel) => {
    const updatedRow = { ...newRow };
    setRows((prevRows) => {
      const newRows = prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
      return calculateTotals(newRows);
    });

    return updatedRow;
  };

  return (
    <DataGrid<RowModel>
      columns={columns}
      rows={rows}
      rowHeight={40}
      pinnedColumns={{ left: ["name"] }}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      disableColumnSorting
      showCellVerticalBorder
      showColumnVerticalBorder
      editMode="cell"
      isCellEditable={({ row, colDef }) => colDef.editable === true && row.type === "Metric"}
      processRowUpdate={handleRowUpdate}
      containerSx={{ height: 980 }}
      sx={(t) => ({
        borderColor: t.palette.divider,
        ".MuiDataGrid-columnHeaders": {
          borderTop: `1px solid ${t.palette.divider}`,
          ".MuiDataGrid-columnHeader": {
            ":first-of-type": {
              borderLeft: `1px solid ${t.palette.divider}`,
            },
            "&.MuiDataGrid-columnHeader--last": {
              borderRight: `1px solid ${t.palette.divider}`,
            },
            "&.readonly-header": {
              backgroundColor: grey[50],
            },
          },
        },
        ".MuiDataGrid-columnHeaderTitleContainerContent": {
          width: "100%",
        },
        ".MuiDataGrid-row": {
          ":last-of-type": {
            borderBottom: `1px solid ${t.palette.divider}`,
            ".MuiDataGrid-cell--pinnedLeft": {
              borderBottom: `1px solid ${t.palette.divider}`,
            },
          },
          ".MuiDataGrid-cell": {
            ":first-of-type": {
              borderLeft: `1px solid ${t.palette.divider}`,
            },
            ":last-of-type": {
              borderRight: `1px solid ${t.palette.divider}`,
            },
          },
          "& .readonly-cell": {
            backgroundColor: grey[50],
          },
          "& .total-cell": {
            backgroundColor: grey[200],
            fontWeight: "bold",
          },
        },
      })}
    />
  );
};

export default MonthlyFinancialsInputTable;
